import { Alert, AlertDescription, AlertIcon, AlertTitle } from "@chakra-ui/react"

type ErrorProps = {
  error: string | null | undefined;
}

export const Error = ({ error }: ErrorProps) => {
  if (! error) {
    return <></>
  }

  return (
    <Alert status="error">
      <AlertIcon />
      <AlertTitle>Erro!</AlertTitle>
      <AlertDescription>{error}</AlertDescription>
    </Alert>
  )
}