import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Center,
  CircularProgress,
  CloseButton,
  Flex,
  IconButton,
  Text,
} from "@chakra-ui/react";
import {
  faBoxes,
  faCog,
  faPaperPlane,
  faRefresh,
  faSignOut,
  faTruckLoading,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useIsAuth } from "../../hooks/useIsAuth";
import { RootState } from "../app/mainReducer";
import { inventoryActions } from "../inventory/reducer";
import { loginActions } from "../login/reducer";
import { cargasActions } from "./reducer";
import { ShippingItem } from "./shippingItem";
import Scan from "../../components/KoderQrCode/KoderQrCodeScanner";

export const CargasList: React.FC<{}> = () => {
  useIsAuth();

  const navigate = useNavigate();

  const usuario = useSelector((state: RootState) => state.login.usuario);

  const isLoading = useSelector((state: RootState) => state.cargas.isLoading);

  const cargas = useSelector((state: RootState) => state.cargas.cargas);
  const error = useSelector((state: RootState) => state.cargas.error);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(cargasActions.requestCargas());
  }, [dispatch]);

  return (
    <Flex direction="column" w="100%" h="100vh">
      <Flex 
        direction="column" 
        bgColor="blue.300" 
        p={4}
        position="sticky"
        top={0}
        zIndex={1}
      >
        <Center flexDirection="column" mb={4}>
          <Text fontSize="xl" fontWeight="bold" mb={2}>
            Cargas SAERP
          </Text>
          <Text fontSize="sm" mb={2}>Usuário: {usuario}</Text>
          <Button size="xs" borderRadius="full" colorScheme="blue">
            v1.0.10
          </Button>
        </Center>

        <Flex 
          justify="center" 
          wrap="wrap" 
          gap={2}
          maxW="100%"
        >
          <IconButton
            colorScheme="blue"
            aria-label="Configurações"
            size={{ base: "md", sm: "lg" }}
            onClick={() => navigate(`/config`)}
            icon={
              <FontAwesomeIcon
                icon={faCog}
                title="Configurações"
              />
            }
          />
          <IconButton
            colorScheme="blue"
            aria-label="Consumo"
            size={{ base: "md", sm: "lg" }}
            onClick={() => navigate(`/consume`)}
            icon={
              <FontAwesomeIcon
                icon={faPaperPlane}
                title="Consumo"
              />
            }
          />
          <IconButton
            colorScheme="blue"
            aria-label="Inventario"
            size={{ base: "md", sm: "lg" }}
            onClick={() => {
              dispatch(inventoryActions.startInventory());
              navigate(`/inventory`);
            }}
            icon={
              <FontAwesomeIcon
                icon={faBoxes}
                title="Inventario"
              />
            }
          />
          <IconButton
            colorScheme="gray"
            aria-label="Estoque filial"
            size={{ base: "md", sm: "lg" }}
            onClick={() => {
              dispatch(inventoryActions.startInventory());
              navigate(`/efilial`);
            }}
            icon={
              <FontAwesomeIcon
                icon={faTruckLoading}
                title="Estoque Filial"
              />
            }
          />
          <IconButton
            colorScheme="blue"
            aria-label="Recarregar"
            size={{ base: "md", sm: "lg" }}
            onClick={() => dispatch(cargasActions.requestCargas())}
            icon={
              <FontAwesomeIcon
                icon={faRefresh}
                title="Recarregar"
              />
            }
          />
          <IconButton
            colorScheme="red"
            aria-label="Sair"
            size={{ base: "md", sm: "lg" }}
            onClick={() => dispatch(loginActions.logout())}
            icon={
              <FontAwesomeIcon
                icon={faSignOut}
                title="Sair"
              />
            }
          />
        </Flex>
      </Flex>

      <Flex 
        direction="column" 
        px={{ base: 2, md: 4 }} 
        py={3}
        flex={1}
        overflowY="auto"
        w={{ base: "100%", sm: "50%" }}
        mx={"auto"}
      >
        {isLoading && (
          <Center py={4}>
            <CircularProgress isIndeterminate={true} />
          </Center>
        )}
        
        {error && (
          <Alert status="error" minH="3rem" mb={3} borderRadius="md">
            <AlertIcon />
            <Flex direction="column" flex={1}>
              <AlertTitle>Erro!</AlertTitle>
              <AlertDescription>{error}</AlertDescription>
            </Flex>
            <CloseButton
              onClick={() => dispatch(cargasActions.clearError())}
            />
          </Alert>
        )}

        <Flex direction="column" gap={2}>
          {cargas &&
            cargas.map((carga) => (
              <ShippingItem
                key={carga.UKEY}
                onClick={() => navigate(`/carga/${carga.UKEY}`)}
                carga={carga}
              />
            ))}
        </Flex>
      </Flex>
    </Flex>
  );
};