import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Center,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  Select,
  Text,
} from "@chakra-ui/react";
import { faArrowLeft, faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { QtdInput } from "../../components/Qtdinput";
import { useIsAuth } from "../../hooks/useIsAuth";
import { RootState } from "../app/mainReducer";
import { cargasActions } from "./reducer";
import Scan from "../../components/KoderQrCode/KoderQrCodeScanner";

export const Consume: React.FC<{}> = () => {
  useIsAuth();

  const navigate = useNavigate();

  const [codigoPallet, setCodigoPallet] = useState("");
  const [motivo, setMotivo] = useState("Picking");
  const [qtd, setQtd] = useState(0);
  const [isBarcodeScanOpen, setBarcodeScanOpen] = useState(false);
  const dispatch = useDispatch();
  const dadosPallet = useSelector(
    (state: RootState) => state.cargas.dadosPallet
  );
  const error = useSelector((state: RootState) => state.cargas.error);
  const isConsuming = useSelector(
    (state: RootState) => state.cargas.isConsuming
  );

  useEffect(() => {
    dispatch(cargasActions.clearCheckPallet());
    setBarcodeScanOpen(true);
  }, [dispatch]);

  if (isBarcodeScanOpen) {
    return (
      <Flex
        direction="column"
        w="100vw"
        h="100vh"
        overflowX="hidden"
        overflowY="hidden"
      >
        {isBarcodeScanOpen && <Scan setValue={(code: string) => {
          setBarcodeScanOpen(false);
          if (code) {
            setCodigoPallet(code);
            setBarcodeScanOpen(false);
            dispatch(
              cargasActions.requestCheckPallet({
                codigoPallet: code,
                setQtd,
                itens: {},
              })
            );
          }
        }} />}
      </Flex>
    );
  }

  return (
    <Flex
      direction="column"
      w="100vw"
      h="100vh"
      overflowX="hidden"
      overflowY="scroll"
      py="1rem"
      px="0.5rem"
    >
      <Flex direction="row">
        <IconButton
          colorScheme="blue"
          aria-label="Voltar"
          mr="0.4rem"
          onClick={() => navigate(`/`)}
          icon={
            <FontAwesomeIcon
              style={{ marginLeft: "1rem", marginRight: "1rem" }}
              icon={faArrowLeft}
              title="Voltar"
              color="#eee"
            />
          }
        />
      </Flex>
      <Center my="0.5rem">
        <Text fontSize="2xl">Consumir pallet</Text>
      </Center>
      <Center my="0.5rem">
        <Text fontSize="lg">Código do Pallet</Text>
      </Center>

      <Flex my="0.5rem" mx="0.5rem">
        <InputGroup size="md">
          <Input
            value={codigoPallet}
            onChange={(e) => setCodigoPallet(e.target.value)}
            onBlur={() =>
              dispatch(
                cargasActions.requestCheckPallet({
                  codigoPallet,
                  setQtd,
                  itens: {},
                })
              )
            }
            placeholder="Código do pallet"
          />
          <InputRightAddon>
            <IconButton
              aria-label="Ler código com a camera"
              icon={<FontAwesomeIcon icon={faCamera} />}
              onClick={() => setBarcodeScanOpen(true)}
            />
          </InputRightAddon>
        </InputGroup>
      </Flex>
      <Center my="0.5rem">
        <Text fontSize="lg">Motivo</Text>
      </Center>
      <Box my="0.5rem" mx="1rem">
        <Select
          value={motivo}
          onChange={(e) => setMotivo(e.target.value)}
          placeholder="Selecione um motivo"
        >
          <option value="Picking">Picking</option>
          <option value="Mistura">Mistura</option>
          <option value="Almoxarifado">Almoxarifado</option>
          <option value="Laboratorio">Laboratorio</option>
          <option value="Não conforme">Não conforme</option>
          <option value="Ajuste de Pallet">Ajuste de Pallet</option>
          <option value="Reprovado depois de Aprovado">Reprovado depois de Aprovado</option>
        </Select>
      </Box>
      <Center my="0.5rem">
        <Text fontSize="lg">Quantidade Consumida</Text>
      </Center>
      <Center my="0.5rem">
        <QtdInput
          value={qtd}
          onChange={(qtd) => typeof qtd === "number" && setQtd(qtd)}
        />
      </Center>
      {dadosPallet && !dadosPallet?.k15 && !dadosPallet?.j07 && (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>O Pallet não existe!</AlertTitle>
          <AlertDescription>
            O Pallet {codigoPallet} não existe no banco de dados do sistema!
          </AlertDescription>
        </Alert>
      )}
      {error && (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>Erro na carga!</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      {dadosPallet?.d04?.D04_001_C && (
        <Text>
          Item: {dadosPallet?.d04.D04_001_C} - {dadosPallet.d04.D04_002_C}
        </Text>
      )}
      {motivo === "Picking" && dadosPallet?.d04?.D04_118_C && (
        <Text>Endereço do pallet: {dadosPallet.d04.D04_118_C}</Text>
      )}
      <Center>
        <Button
          isLoading={isConsuming}
          disabled={qtd <= 0 || `${codigoPallet}`.trim().length < 3}
          onClick={() =>
            dispatch(
              cargasActions.consumeRequest({
                qtd,
                codigoPallet,
                navigate,
                motivo,
              })
            )
          }
          colorScheme="blue"
        >
          Concluir
        </Button>
      </Center>
    </Flex>
  );
};
