import { all } from "redux-saga/effects";
import cargasSaga from "../cargas/saga";
import { efilialSaga } from "../efilial/saga";
import { inventorySaga } from "../inventory/saga";
import { loginSaga } from "../login/saga";
import pickingSaga from "../picking/saga";
import { inspectionSaga } from "../inspection/saga";

export function* mainSaga() {
  yield all([loginSaga(), cargasSaga(), pickingSaga(), inventorySaga(), efilialSaga(), inspectionSaga()]);
}