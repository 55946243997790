import {
  Box,
  Button,
  Center,
  Flex,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { faEye, faEyeSlash, faUnlink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useSwipeable } from "react-swipeable";

export const Item: React.FC<{
  item: Record<string, any>;
  onRipped: (item: Record<string, any>) => void;
}> = ({ item, onRipped }) => {
  const [isSwiping, setIsSwiping] = useState(false);
  const [isBlocksOpen, setBlocksOpen] = useState(false);

  const handlers = useSwipeable({
    delta: 50,
    onSwipeStart: () => setIsSwiping(true),
    onSwiping: (event) => {
      if (!isSwiping) {
        return;
      }
      // console.log("swiping", window.innerWidth);
      const swipe_len = (20 * Math.abs(event.deltaX)) / (window.innerWidth / 2);
      if (swipe_len > 20) setIsSwiping(false);

      if (event.dir === "Left" && widthRight < 20) {
        setWidthRight(swipe_len > 20 ? 20 : swipe_len);
      }
      if (event.dir === "Right" && widthRight > 0) {
        setWidthRight(swipe_len > 20 ? 0 : 20 - swipe_len);
      }
    },
  });

  const [widthRight, setWidthRight] = useState(0);

  const missing = Math.round((item.qtd - item.read) * 1000) / 1000;
  const qtdPallets = Math.floor(item.qtd / item.d04.D04_074_N);
  const qtdPicking = item.qtd % item.d04.D04_074_N;

  return (
    <Flex
      direction="row"
      w="100%"
      mt="0.1rem"
      bgColor={item.read >= item.qtd ? "green.300" : "red.300"}
    >
      <Box py="0.5rem" px="0.4rem" {...handlers} flexGrow={10}>
        {missing > 0 && (
          <Text fontSize="lg" as="b" color="red.700">
            Faltam: {missing}
          </Text>
        )}
        <Flex direction="row" justifyContent="space-between">
          <Text fontSize="2xl" as="b">
            {item.d04.D04_001_C.trim()}
          </Text>
          <Text fontSize="2xl" as="b">
            {item.read}/{item.qtd}
          </Text>
        </Flex>
        <Flex direction="row" justifyContent="space-between">
          <Box>
            <Text fontSize="md" color={'gray.800'} fontWeight={600}>{item.d04.D04_002_C.trim()}</Text>
            <Text fontSize="md" color={'gray.800'}>
              Endereço Piking: {item.d04.D04_118_C.trim() || "SEM ENDEREÇO"}
            </Text>
            <Text fontSize="md" color={'gray.800'}>Quantidade Pallets: {qtdPallets}</Text>
            <Text fontSize="md" color={'gray.800'}>Quantidade Picking: {qtdPicking}</Text>
          </Box>
          <Center mx="0.5rem">
            <FontAwesomeIcon
              icon={!isBlocksOpen ? faEye : faEyeSlash}
              onClick={() => setBlocksOpen(!isBlocksOpen)}
            />
          </Center>
        </Flex>

        {isBlocksOpen && (
            <Box 
              mt="2" 
              p="3" 
              bgColor="gray.50" 
              borderRadius="md"
            >
              <Text fontWeight="bold" mb="2">Detalhes</Text>
              <Flex direction="column" gap="1">
                <Text>Pallets Lidos: {item.k20s?.length || 0}</Text>
                <Text>
                  Rasgados: {item.k20s
                ? item.k20s.reduce(
                    (_: number, k20: any) => _ + k20.K20_002_N || 0,
                    0
                  )
                : 0}
                </Text>
                <UnorderedList>
                  {item.k20s &&
                    item.k20s.map((k20: any) => (
                      <ListItem key={k20.k15?.K15_001_C?.trim()}>
                        Pallet {k20.k15?.K15_001_C?.trim()}: {k20.K20_002_N || 0}/
                        {k20.K20_001_N}
                      </ListItem>
                    ))}
                </UnorderedList>
              </Flex>
            </Box>
          )}
      </Box>
      <Button
        right={widthRight - 20}
        minW="5rem"
        display={widthRight > 0 ? "flex" : "none"}
        h="auto"
        colorScheme="red"
        onClick={() => onRipped(item.d04)}
      >
        <FontAwesomeIcon icon={faUnlink} />
      </Button>
    </Flex>
  );
};
