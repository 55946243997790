import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

export type AddPhotoRequest = {
  ukey: string;
  base64: string;
}

export type RemovePhotoRequest = {
  archive_ukey: string;
  n01_ukey: string;
}

export type InspectionPhoto = {
  data: string;
  descricao: string;
  nome_arquivo: string;
  timestamp: string;
  u04_par: string;
  u04_ukeyp: string;
  ukey: string;
  url: string;
};

export type InspectionAgrupament = {
  N01_001_C: string;
  UKEY: string;
  ff08: {
    FF08_001_C: string;
  };
};

export type Inspection = {
  agrupamento: InspectionAgrupament;
  photos: InspectionPhoto[],
};

type TInitialState = {
  inspection: Inspection;
  error: string;
  success: string;
  isSaving: boolean;
  isLoading: boolean;
};

const initialState: TInitialState = {
  inspection: {
    agrupamento: {
      UKEY: '',
      N01_001_C: '',
      ff08: {
        FF08_001_C: '',
      },
    },
    photos: [],
  },
  error: "",
  success: "",
  isSaving: false,
  isLoading: false,
};

const reducer = createSlice({
  name: "inspection",
  initialState,
  reducers: {
    setSuccess(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = '';
      state.success = payload;
    },
    setError(state, { payload }: PayloadAction<string>) {
      state.isLoading = false;
      state.error = payload;
      state.success = '';
    },
    getInspection(state, { payload }: PayloadAction<string>) {
      state.isLoading = true;
      state.error = '';
      state.success = '';
      if (payload != state.inspection.agrupamento.UKEY) {
        state.inspection = initialState.inspection;
      }
    },
    getInspectionSuccess(state, { payload }: PayloadAction<Inspection>) {
      state.isLoading = false;
      state.error = '';
      state.success = '';
      state.inspection = payload;
    },
    addPhoto(state, { payload }: PayloadAction<AddPhotoRequest>) {
      state.success = '';
      state.error = '';
      state.isLoading = true;
    },
    removePhoto(state, _: PayloadAction<RemovePhotoRequest>) {
      state.error = '';
      state.success = '';
      state.isLoading = true;
    },
    setLoading(state, { payload }: PayloadAction<boolean>) {
      state.isLoading = payload;
    },
  },
});

export const inspectionActions = reducer.actions;

export default reducer.reducer;