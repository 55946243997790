import React, {useEffect} from "react";
import useState from 'react-usestateref';
import {beep as beepNow, WORKER_TYPE} from "./helpers";
import "./css/scan.css";
import {
  Button,
} from "@chakra-ui/react";

const CANVAS_SIZE = {
  WIDTH: window.innerWidth,
  HEIGHT: window.innerHeight - 150
};

const CAPTURE_OPTIONS = {
  audio: false,
  video: {facingMode: "environment"}
}

const sw = CANVAS_SIZE.WIDTH;
const sh = CANVAS_SIZE.HEIGHT;
const dw = sw;
const dh = sh;
const dx = 0;
const dy = 0;
let sx = 0;
let sy = 0;

const crossHairSvg = "M77.125 148.02567c0-3.5774 2.73862-6.27567 6.37076-6.27567H119V117H84.0192C66.50812 117 52 130.77595 52 148.02567V183h25.125v-34.97433zM237.37338 117H202v24.75h35.18494c3.63161 0 6.69006 2.69775 6.69006 6.27567V183H269v-34.97433C269 130.77595 254.88446 117 237.37338 117zM243.875 285.4587c0 3.5774-2.73863 6.27567-6.37076 6.27567H202V317h35.50424C255.01532 317 269 302.70842 269 285.4587V251h-25.125v34.4587zM83.49576 291.73438c-3.63213 0-6.37076-2.69776-6.37076-6.27568V251H52v34.4587C52 302.70842 66.50812 317 84.0192 317H119v-25.26563H83.49576z";
const crossHairWidth = 217, crossHairHeight = 200, x0 = 110, y0 = 150;

export default function Scan({
  setValue,
  decode = true,
  worker = WORKER_TYPE.WASM,
  scanRate = 250,
  bw = false,
  crosshair = false
}) {
  const [scanning, setScanning] = useState(false);

  const [bwRef] = useState(bw);

  const [video] = useState(document.createElement("video"));

  let qrworker = null;
  let canvasElement = null;
  let canvas = null;
  let oldTime = 0;

  video.onplaying = () => {
    sx = (video.videoWidth - CANVAS_SIZE.WIDTH) / 2;
    sy = (video.videoHeight - CANVAS_SIZE.HEIGHT) / 2;
  };

  const initWorker = () => {
    qrworker = new Worker(window.location.origin + '/' + worker + "Worker.js");
    qrworker.onmessage = async ev => {
      if (ev.data != null) {
        qrworker.terminate();
        const result = ev.data;
        const res = result.data;
        await stopScan(res);
        beepNow();
      }
    };
  };

  const startScan = async () => {
    initWorker();
    canvasElement = document.getElementById("canvas");
    canvas = canvasElement.getContext("2d", {willReadFrequently: true});

    try {
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        throw new Error('getUserMedia is not supported in this browser');
      }
      video.srcObject = await navigator.mediaDevices.getUserMedia(CAPTURE_OPTIONS);
      video.setAttribute("playsinline", "true");
      await video.play();
      setScanning(true);

      requestAnimationFrame(tick);
    } catch (err) {
      stopScan().then();
      console.log("stopped by the user");
      alert(err);
    }
  };

  useEffect(() => {
    startScan()
  }, []);

  const stopScan = async (readedCode = '') => {
    setScanning(false);
    await video.pause();
    if (video.srcObject) {
      video.srcObject.getVideoTracks().forEach(track => track.stop());
      video.srcObject = null;
    }
    setValue(readedCode);
  };

  const tick = (time) => {
    if (video.readyState === video.HAVE_ENOUGH_DATA) {
      canvas.drawImage(video, sx, sy, sw, sh, dx, dy, dw, dh);

      if (bwRef.current) monochromize();
      if (crosshair) drawCrosshair();
      if (scanning) requestAnimationFrame(tick);
      if (decode) recogniseQRcode(time);
    }
    requestAnimationFrame(tick);
  };

  const monochromize = () => {
    let imgd = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
    let pix = imgd.data;
    for (let i = 0; i < pix.length; i += 4) {
      let gray = pix[i] * 0.3 + pix[i + 1] * 0.59 + pix[i + 2] * 0.11;
      pix[i] = gray;
      pix[i + 1] = gray;
      pix[i + 2] = gray;
    }
    canvas.putImageData(imgd, 0, 0);
  };

  const drawCrosshair = () => {
    canvas.fillStyle = "rgba(255,255,255,0.4)";
    const shape = new Path2D(crossHairSvg);
    canvas.fill(shape);
  };

  const recogniseQRcode = (time) => {
    if (time - oldTime > scanRate) {
      oldTime = time;
      let imageData;
      if (crosshair)
        imageData = canvas.getImageData(x0, y0, crossHairWidth, crossHairHeight);
      else
        imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
      qrworker.postMessage({width: imageData.width, height: imageData.height});
      qrworker.postMessage(imageData, [imageData.data.buffer]);
    }
  };

  const onBtnClickHandler = async (e) => {
    e.preventDefault();
    if (scanning) await stopScan(); else await startScan();
  };

  const renderCanvas = () => {
    return <canvas style={{ margin: 'auto' }} id="canvas" className="scanCanvas" width={CANVAS_SIZE.WIDTH} height={CANVAS_SIZE.HEIGHT} />
  };

  const renderScan = () => {
    return (
      <div className="scan">
        {renderCanvas()}
        
        <Button
          colorScheme="red"
          position="relative"
          width="100vw"
          onClick={onBtnClickHandler}
        >
          Voltar
        </Button>
      </div>
    );
  };

  return (
    <div className="">
      {renderScan()}
    </div>
  )
};