import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Center,
  CircularProgress,
  CloseButton,
  Flex,
  IconButton,
  Select,
  Text,
  Box,
} from "@chakra-ui/react";
import {
  faArrowLeft,
  faBan,
  faBarcode,
  faRefresh,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { formatNumber } from "../../utils/formatNumber";
import { formatPlate } from "../../utils/formatPlate";
import { getTipoCarga } from "../../utils/getTipoCarga";
import { RootState } from "../app/mainReducer";
import { cargasActions } from "./reducer";
import _ from "underscore";
import { Item } from "./item";
import { LoaderButton } from "../../components/LoaderButton";
import { Ripped } from "./ripped";

export const Carga: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const { ukey } = useParams();
  const carga = useSelector((state: RootState) => state.cargas.carga || {});
  const itens = useSelector((state: RootState) => state.cargas.itens || {});
  const error = useSelector((state: RootState) => state.cargas.error);
  const isCargaLoading = useSelector(
    (state: RootState) => state.cargas.isLoadingCarga
  );
  const isRipping = useSelector((state: RootState) => state.cargas.isRipping);
  const carguistas = useSelector(
    (state: RootState) => state.cargas.carguistas || []
  );
  const plateFormated = carga.ff08
    ? formatPlate(carga.ff08.FF08_001_C.trim())
    : "SEM PLACA";
  const [carguista, setCarguista] = useState("");
  const isFinishing = useSelector(
    (state: RootState) => state.cargas.isFinishing
  );

  const [rippedItem, setRippedItem] = useState<Record<string, any> | undefined>(
    undefined
  );

  const navigate = useNavigate();

  const pesoTotal =
    carga.j14s &&
    carga.j14s.reduce((_: any, v: any) => {
      if (v.j15s) {
        let pesoj15 = v.j15s.reduce((_: any, j15: any) => {
          if (j15.j07) {
            let pesoJ08 = j15.j07.j08s.reduce((_: any, j08: any) => {
              if (j08.d04) {
                return _ + j08.J08_003_N * j08.d04.D04_022_B;
              }
              return _;
            }, 0);
            return _ + pesoJ08;
          }
          return _;
        }, 0);
        return _ + pesoj15;
      }
      return _;
    }, 0);

  useEffect(() => {
    ukey && dispatch(cargasActions.requestCarga(ukey));
    dispatch(cargasActions.carguistaRequest());
    setCarguista("");
  }, [dispatch, ukey]);

  if (isCargaLoading || isRipping) {
    return (
      <Flex
        w="100vw"
        h="100vh"
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress isIndeterminate={true} />
      </Flex>
    );
  }
  if (rippedItem) {
    return (
      <Ripped
        item={rippedItem}
        carga={ukey || ""}
        onClose={() => setRippedItem(undefined)}
      />
    );
  }

  const voltarClick = () => {
    window.confirm(
      "Tem certeza que deseja parar o carregamento desta carga?"
    ) && navigate("/");
  };

  const qtdFaltando = _.reduce(itens, (a, n) => a + n.qtd - n.read, 0);
  console.log("itens", itens);

  return (
    <Flex direction="column" w="100%" h="100vh">
      <Flex 
        direction="column" 
        bgColor="blue.500"
        color="white"
        px="4"
        py="3"
        position="sticky"
        top="0"
        zIndex="10"
      >
        <Box
          w={{ base: "100%", sm: "50%" }}
          mx={"auto"}
        >
          <Flex
            justifyContent="space-between" mb="3"
          >
            <IconButton
              colorScheme="whiteAlpha"
              aria-label="Voltar"
              onClick={voltarClick}
              icon={<FontAwesomeIcon icon={faArrowLeft} />}
            />
            <IconButton
              colorScheme="whiteAlpha"
              aria-label="Recarregar"
              onClick={() => ukey && dispatch(cargasActions.requestCarga(ukey))}
              icon={<FontAwesomeIcon icon={faRefresh} />}
            />
          </Flex>

          <Flex direction="column" gap="2">
            <Text fontSize="2xl" fontWeight="bold">{plateFormated}</Text>
            <Text><b>Tipo carga:</b> {getTipoCarga(carga)} - <b>Peso:</b> {formatNumber(pesoTotal, 2, true)}</Text>
            <Text><b>Motorista:</b> {carga.a03?.A03_003_C || "Não identificado"}</Text>
          </Flex>

          <Button
            colorScheme="teal"
            width={'full'}
            onClick={() => navigate(`/inspection/${ukey as string}`)}
          >
            Fazer vistoria
          </Button>
          {qtdFaltando <= 0 && (
            <Flex direction="column" gap="2" mt="3">
              <Select
                value={carguista}
                onChange={(e) => setCarguista(e.target.value)}
                placeholder="Selecione um carguista"
                bg="white"
                color="black"
              >
                {carguistas?.map((c) => (
                  <option key={c.UKEY} value={c.UKEY}>{c.A46_002_C}</option>
                ))}
              </Select>
              
              <LoaderButton
                onClick={() =>
                  dispatch(
                    cargasActions.concluirRequest({
                      UKEY: carga.UKEY,
                      operador: carguista,
                      navigate,
                    })
                  )
                }
                isLoading={isFinishing}
                disabled={!carguista}
                colorScheme="green"
                size="md"
              >
                Concluir carregamento
              </LoaderButton>
            </Flex>
          )}
        </Box>
      </Flex>

      <Flex direction="column" overflowY="scroll" mx={"auto"} w={{ base: "100%", sm: "50%" }}>
        {error && (
          <Alert status="error">
            <AlertIcon />
            <Box flex="1">
              <AlertTitle>Erro!</AlertTitle>
              <AlertDescription>{error}</AlertDescription>
            </Box>
            <CloseButton onClick={() => dispatch(cargasActions.clearError())} />
          </Alert>
        )}
        {_.map(itens, (item: any, i: number) => (
          <Item
            item={item}
            key={`${i}`}
            onRipped={(item) => setRippedItem(item)}
          />
        ))}
      </Flex>

      <Flex position={"relative"} mx={"auto"} w={{ base: "100%", sm: "50%" }}>
        <Button
          colorScheme="red"
          position="absolute"
          bottom="0.5rem"
          left="0.5rem"
          borderRadius="1rem"
          onClick={() => navigate(`/pallet/cancel/${ukey}`)}
        >
          <FontAwesomeIcon icon={faBan} />
        </Button>
        <Button
          colorScheme="blue"
          position="absolute"
          bottom="0.5rem"
          right="0.5rem"
          borderRadius="1rem"
          onClick={() => navigate(`/pallet/${ukey}`)}
        >
          <FontAwesomeIcon icon={faBarcode} />
        </Button>
      </Flex>
    </Flex>
  );
};
