import { takeLatest } from "redux-saga/effects";
import { all } from "redux-saga/effects";
import { put } from "redux-saga/effects";
import { call } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { apiCall } from "../../utils/apiCall";
import { AddPhotoRequest, Inspection, inspectionActions, RemovePhotoRequest } from "./reducer";
import { AxiosResponse } from "axios";

function* getInspectionWorker({ payload }: PayloadAction<string>) {
  try {
    const res: AxiosResponse<Inspection> = yield call(apiCall, {
      url: `/apiCargas/inspection/${payload}`,
      method: "GET",
    });

    yield put(inspectionActions.getInspectionSuccess(res.data));
  } catch (error: any) {
    yield put(
      inspectionActions.setError(
        `Erro ao buscar vistoria: ${error?.response?.data ? error.response.data.error : JSON.stringify(error)
        }`
      )
    );
  }
}

function* addPhotoWorker({ payload }: PayloadAction<AddPhotoRequest>) {
  try {
    const res: AxiosResponse<Inspection> = yield call(apiCall, {
      url: `/apiCargas/inspection/add-image/${payload.ukey}`,
      method: "POST",
      data: {
        base64: payload.base64,
      },
    });

    console.log(res.data);

    yield put(inspectionActions.getInspection(payload.ukey));
  } catch (error: any) {
    yield put(
      inspectionActions.setError(
        `Erro ao salvar foto: ${error?.response?.data ? error.response.data.error : JSON.stringify(error)
        }`
      )
    );
  }
}

function* removePhotoWorker({ payload }: PayloadAction<RemovePhotoRequest>) {
  try {
    const res: AxiosResponse<Inspection> = yield call(apiCall, {
      url: `/apiCargas/inspection/remove-image/${payload.archive_ukey}`,
      method: "DELETE",
    });

    console.log(res.data);

    yield put(inspectionActions.getInspection(payload.n01_ukey));
  } catch (error: any) {
    yield put(
      inspectionActions.setError(
        `Erro ao remover foto: ${error?.response?.data ? error.response.data.error : JSON.stringify(error)
        }`
      )
    );
  }
}
export function* inspectionSaga() {
  yield all([
    takeLatest(inspectionActions.getInspection.type, getInspectionWorker),
    takeLatest(inspectionActions.addPhoto.type, addPhotoWorker),
    takeLatest(inspectionActions.removePhoto.type, removePhotoWorker),
  ]);
}
