import {
  Box,
  Button,
  Flex,
  IconButton,
  Text,
  Image,
  SimpleGrid,
} from "@chakra-ui/react";
import {
  faArrowLeft,
  faCamera,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../app/mainReducer";
import { inspectionActions } from "./reducer";
import { Loader } from "./components/loader";
import { Error } from "./components/error";

export const ShippingInspection = () => {
  const navigate = useNavigate();
  const { ukey } = useParams();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const isLoading = useSelector((state: RootState) => state.inspection.isLoading)
  const error = useSelector((state: RootState) => state.inspection.error)

  const handlePhotoCapture = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      dispatch(inspectionActions.setLoading(true));
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(inspectionActions.addPhoto({
          ukey: ukey as string,
          base64: reader.result as string
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const dispatch = useDispatch();

  const getInepection = () => {
    dispatch(inspectionActions.getInspection(ukey as string));
  }

  useEffect(() => {
    getInepection();
  }, [dispatch, ukey]);

  const deleteInspectionPhoto = (archive_ukey: string) => {
    if (! window.confirm('Confirma apagar esta foto?')) {
      return;
    }

    dispatch(inspectionActions.removePhoto({
      archive_ukey,
      n01_ukey: ukey as string,
    }));
  }

  const inspection = useSelector((state: RootState) => state.inspection.inspection);

  return (
    <Flex direction="column" w="100%" h="100vh">
      {isLoading && <Loader />}
    
      <Error error={error} />

      <Flex
        direction="column"
        bgColor="blue.500"
        color="white"
        px="4"
        py="3"
        position="sticky"
        top="0"
        zIndex="10"
      >
        <Box w={{ base: "100%", sm: "50%" }} mx="auto">
          <Flex justifyContent="space-between" mb="3">
            <Button
              colorScheme="whiteAlpha"
              onClick={() => navigate(`/carga/${ukey as string}`)}
              leftIcon={<FontAwesomeIcon icon={faArrowLeft} />}
            >Voltar</Button>
          </Flex>

          <Text fontSize="2xl" fontWeight="bold">
            Vistoria de carregamento
          </Text>
          <Text><b>Agrupamento:</b> {inspection.agrupamento?.N01_001_C} - <b>Placa:</b> {inspection.agrupamento?.ff08?.FF08_001_C}</Text>
        </Box>
      </Flex>

      <Flex position={'relative'} direction="column" p="4" overflowY="auto" mx="auto" w={{ base: "100%", sm: "50%" }}>
        <SimpleGrid columns={{ base: 2, md: 3 }} spacing="4">
          {(inspection?.photos ?? []).map((photo, index) => (
            <Box key={photo.ukey} position="relative">
              <Image
                src={photo.url}
                alt={`Foto ${index + 1}`}
                borderRadius="md"
                objectFit="cover"
              />
              <Flex
                position="absolute"
                top="2"
                left="2"
                gap="2"
              >
                <IconButton
                  size="sm"
                  colorScheme="red"
                  aria-label="Excluir foto"
                  icon={<FontAwesomeIcon icon={faTrash} />}
                  onClick={() => deleteInspectionPhoto(photo.ukey)}
                />
              </Flex>
            </Box>
          ))}
        </SimpleGrid>
      </Flex>

      <Flex
        px="2"
        py="2"
        bottom={0}
        position="fixed"
        width={'full'}
        justifyContent={"center"}
      >
        <Box w={{ base: "100%", sm: "50%" }}>
          <Button
            borderRadius="0.5rem"
            onClick={() => fileInputRef.current?.click()}
            w="full"
            colorScheme="blue"
            leftIcon={<FontAwesomeIcon icon={faCamera} />}
          >
            Tirar foto
          </Button>
          <input
            type="file"
            accept="image/*"
            capture="environment"
            ref={fileInputRef}
            onChange={handlePhotoCapture}
            style={{ display: "none" }}
          />
        </Box>
      </Flex>
    </Flex>
  );
};