import { Box, Spinner } from "@chakra-ui/react";

export const Loader = () => {
  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      width="100%"
      height="100%"
      bgColor="rgba(255, 255, 255, 0.8)"
      display="flex"
      alignItems="center"
      justifyContent="center"
      zIndex="20"
    >
      <Spinner size="xl" />
    </Box>
  );
};