import { Heading } from "@chakra-ui/react";
import { Routes, Route } from "react-router-dom";
import { Consume } from "../cargas/consume";
import { Carga } from "../cargas/detail";
import { CargasList } from "../cargas/list";
import { ReadBlock } from "../cargas/readBlock";
import { Config } from "../config/Config";
import { Efilial } from "../efilial/efilial";
import { ReadBlockEfilial } from "../efilial/readBlock";
import { Inventory } from "../inventory/inventory";
import { ReadBlockInventory } from "../inventory/readBlock";
import { Login } from "../login/login";
import { PickingReport } from "../picking/report";
import { ShippingInspection } from "../inspection/shippingInspection";

const NotFound: React.FC<{}> = () => {
  return <Heading>Pagina não encontrada</Heading>;
};

export const Router: React.FC<{}> = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<CargasList />} />
      <Route path="/config" element={<Config />} />
      <Route path="/pickingReport" element={<PickingReport />} />
      <Route path="/consume" element={<Consume />} />
      <Route path="/carga/:ukey" element={<Carga />} />
      <Route path="/pallet/:ukey" element={<ReadBlock isCanceling={false} />} />
      <Route
        path="/pallet/cancel/:ukey"
        element={<ReadBlock isCanceling={true} />}
      />
      <Route path="/inventory" element={<Inventory />} />
      <Route path="/inventory/pallet" element={<ReadBlockInventory />} />
      <Route path="/efilial" element={<Efilial />} />
      <Route path="/efilial/pallet" element={<ReadBlockEfilial />} />
      <Route path="/inspection/:ukey" element={<ShippingInspection />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};